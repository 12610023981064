import * as React from 'react'
import { Link } from 'gatsby'
import LogoMarkDark from '../images/logo-mark-teal-500-cyan-600.svg'


const PasswordResetPage = () => (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <LogoMarkDark className="mx-auto h-12 w-auto" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Your password has been reset</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Check your inbox for a link to <Link to="/login">log back in</Link>.
        </p>
      </div>
    </div>
)

export default PasswordResetPage
